.block-search-form {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;

  & .block-title {
    display: none;
  }

  & .block-content {
    float: right;
    width: 100%;

    @media (--screen-md) {
      width: 61%;
      padding-left: 40px;
      padding-right: 40px;
    }

    @media (--screen-lg) {
      width: 50%;
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  & .container-inline {
    display: table;
    table-layout: fixed;
    @extend %box-shadow;
  }

  & .form-item,
  & .form-actions {
    display: table-cell;
    vertical-align: middle;

    &::after {
      content: none;
    }
  }

  & .form-item {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    background-color: var(--white);
  }

  & .form-actions {
    width: 1px;
  }

  & .form-text {
    background: transparent;
    border: none;
    width: 100%;
    outline: none;
    font-size: var(--font-size-secondary);
  }

  & .form-submit {
    width: 158px;
    height: 64px;
    background-color: var(--secondary);

    &:hover,
    &:focus {
      background-color: var(--secondaryDarker);
    }

    @media (--screen-md) {
      width: 128px;
    }
  }

  & input[type="text"] {
    background-color: transparent !important;
    border: none !important;
    color: var(--blackAlt) !important;
    padding: 0;
  }
}
