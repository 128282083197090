%link-noeffect {
  &:hover,
  &:focus {
    color: inherit;
  }
}

%link-standard {
  text-decoration: underline;
}

%link-underlined {
  box-shadow: inset 0 -0.2em 0 var(--primaryTouch);
  transition: background 0.2s;

  &:hover,
  &:focus {
    color: var(--black);
    background-color: var(--primaryTouch);
  }
}
