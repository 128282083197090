.pager {
  position: relative;
  text-align: center;
  width: 50%;
  margin: 80px auto;
  padding: 0;

  & .pager-item,
  & .pager-ellipsis,
  & .pager-nav-item {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0 4px;

    & a,
    & span {
      font-size: var(--font-size-secondary);
      line-height: var(--line-height-secondary);
      color: var(--blackAlt);
    }
  }

  & .pager-item {
    & a {
      color: var(--primary);
    }
  }

  & .pager-nav-item {
    position: absolute;
    top: 0;

    & .pager-nav-item-content {
      display: inline;
      color: transparent;
      font-size: 0;
      text-indent: -9999px;
      overflow: hidden;

      &::before {
        color: var(--blackAlt);
        font-size: 1.4rem;
        text-indent: 0;
        padding: 8px 16px;
      }

      &:hover,
      &:focus {
        &::before {
          color: var(--primary);
        }
      }
    }
  }

  & .pager-previous {
    left: -10px;

    & .pager-nav-item-content {
      @extend %icon-previous;
    }
  }

  & .pager-next {
    right: -10px;

    & .pager-nav-item-content {
      @extend %icon-next;
    }
  }

  & .pager-first {
    left: -40px;

    & .pager-nav-item-content {
      @extend %icon-first;
    }
  }

  & .pager-last {
    right: -40px;

    & .pager-nav-item-content {
      @extend %icon-last;
    }
  }

  & .pager-disabled {
    opacity: 0.25;
  }
}


