.comments {
  & .comment {
    padding: 24px;
    border-top: 1px solid var(--tertiary);
  }

  & .permalink {
    @extend %icon-permalink;

    &::after {
      margin-left: 4px;
      vertical-align: middle;
      font-size: var(--font-size-secondary);
      color: var(--tertiary);
    }
  }

  & .comment-title {
    margin-top: 36px;
    margin-bottom: 12px;
  }

  & .links {
    font-size: var(--font-size-secondary);
    text-align: right;

    & a {
      @extend button;
    }
  }
}
