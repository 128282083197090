.view-recent-articles {
  & .views-row {
    @extend %separator-list;
  }

  & .views-field-title {
    & a {
      display: block;

      &:hover {
        color: var(--primary);
      }
    }
  }

  & .views-field-created {
    @extend %list-date;
  }
}
