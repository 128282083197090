.node-with-image-gallery {
  & .field-name-body,
  & ~ .block-disqus {
    padding-left: 80px;
    padding-right: 80px;
  }

  & .field-name-field-image-gallery {
    margin-left: -80px;
  }

  & .callout {
    padding-right: 80px;
    padding-left: 160px;
    margin-right: -80px;
    margin-left: -160px;
  }
}
