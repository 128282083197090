#page {
  border-top: calc(var(--header-height) + 24px) solid transparent;

  @media (--screen-md) {
    border-top-width: calc(var(--header-height-md) + 24px);
  }

  @media (--screen-lg) {
    border-top-width: calc(var(--header-height-lg) + 24px);
  }

  @media (--screen-xl) {
    border-top-width: calc(var(--header-height-xl) + 24px);
  }
}

#page-title {
  line-height: 0.9;
}
