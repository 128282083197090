.content__inner {
  & .node {
    & .content {
      margin-bottom: 80px;

      &:empty {
        margin-bottom: 0;
      }
    }

    & .field-type-field-collection {
      & .content {
        margin-bottom: 0;
      }
    }

    & ~ .block {
      & .block-content {
        margin-bottom: 80px;
      }
    }
  }

  & .view-content {
    & > .view-grouping {
      & > .view-grouping-header {
        @extend h3;
      }
    }
  }

  & .view-grouping {
    margin-bottom: 80px;
  }

  & .views-field-title,
  & .views-field-field-position-title,
  & .views-field-field-organization-name {
    @extend %list-title;
  }

  & .views-field-body {
    margin-top: 12px;
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
  }

  & .views-row {
    margin-top: 36px;

    &:first-child {
      margin-top: 0;
    }
  }
}
