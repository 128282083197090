.menu,
.menu__item {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu__link {
  font-size: var(--font-size-secondary);
}
