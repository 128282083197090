.view-contact {
  & .views-field-title {
    margin-top: 24px;

    & a {
      @extend button;
      @extend %btn-action;
    }
  }
}
