.view-committees {
  overflow: hidden;

  & .view-content {
    @extend .row;
  }

  & .views-row {
    @extend .col-xs-12;
    @extend .col-sm-6;

    @media (--screen-sm) {
      &.views-row-2 {
        margin-top: 0;
      }
    }
  }

  & .views-field-nothing {
    margin-top: 12px;
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);

    & a {
      @extend %link-standard;
    }
  }
}
