*, *::before, *::after {
  box-sizing: inherit;
}

::selection {
  background: #FFEB3B;
}

::placeholder {
  font-style: italic;
}
