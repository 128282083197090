%separator {
  position: relative;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 2px solid transparent;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 48px;
    left: 48px;
    border-top: 2px solid var(--tertiaryLighter);
  }
}

%separator-clear {
  padding-top: 0;
  margin-top: 0;
  border-top: none;

  &::before {
    content: none;
  }
}

%separator-list {
  @extend %separator;

  &:first-child {
    @extend %separator-clear;
  }
}
