.search-active {
  & #page,
  & #skip-link a:focus {
    padding-top: 64px;

    @media (--screen-md) {
      padding-top: 0;
    }
  }
}
