#block-sitewide-breadcrumb-block {
  position: absolute;
  top: -32px;
  color: var(--tertiaryDarker);
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-secondary);

  & .block-content {
    & ol {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    & li {
      padding: 0;
      margin: 0;
      display: inline;

      & a {
        color: var(--black);

        &:hover,
        &:focus {
          color: var(--primary);
        }
      }
    }
  }
}
