.view-employment-opportunities {
  & .view-content {
    margin-bottom: 80px;
  }

  & .views-field-created {
    @extend %list-date;
  }

  & .attachment-after {
    & .view-content {
      margin-bottom: 0;
    }

    & .views-field-nothing {
      & a {
        @extend button;
        @extend %btn-action;
      }
    }
  }
}
