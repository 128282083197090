.region-header {
  float: left;

  & .block {
    position: relative;
    float: left;

    & a {
      font-size: 0;
      color: transparent;
    }
  }
}
