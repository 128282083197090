%list-title {
  & a {
    font-weight: bold;
  }
}

%list-date {
  font-style: italic;
  font-size: 87.5%;
}
