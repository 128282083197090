.container__section {
  padding-top: 92px;
  padding-bottom: 92px;

  @media (--screen-md) {
    padding-top: 124px;
    padding-bottom: 124px;
  }
}

.container__section--page {
  padding-top: 64px;

  @media (--screen-sm) {
    padding-top: 92px;
  }
}
