#block-sitewide-site-name-block {
  display: none;
  transition: opacity 0.2s;
  position: relative;

  @media (--screen-xs) {
    display: block;
  }

  & .block-content {
    & a {
      position: absolute;
      width: 180px;
      height: 68%;
      top: 16%;
      background-image: url(../../img/title-lg.png);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100% auto;

      @media (--screen-xl) {
        width: 250px;
      }
    }
  }
}
