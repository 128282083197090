.view-event-details {
  margin-bottom: 64px;

  & .views-field-field-location {
    margin-top: 12px;

    & div {
      display: inline;
    }

    & .street-block {
      &::after {
        content: ', ';
        display: inline;
        visibility: visible;
      }
    }
  }

  & .views-field-field-cost {
    margin-top: 12px;
  }

  & .views-field-field-registration-link {
    margin-top: 20px;
    margin-bottom: 80px;

    & a {
      @extend button;
      @extend %btn-action;
    }
  }
}
