.footer-main {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-secondary);

  & h2 {
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
    height: 72px;
    margin-top: 0;
    margin-bottom: 0;
  }

  &,
  & .menu__item {
    line-height: 2;
  }

  & .footer-main-column {
    margin-top: 64px;

    &:first-child {
      margin-top: 0;
    }

    @media (--screen-sm) {
      margin-top: 0;
    }
  }
}
