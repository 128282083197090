.footer-bottom {
  padding-top: 80px;
  text-align: center;
  color: var(--blackAlt);

  & .block {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }
}
