.js {
  & .field-name-field-main-image .field-name-field-image,
  & .views-field-field-slide-image .field-content {
    opacity: 0;
    transition: opacity 1s;
  }
}

.addp-loaded {
  & .views-field-field-slide-image .field-content,
  & .field-name-field-main-image .field-name-field-image {
    opacity: 1;
  }
}
