%btn-full {
  display: block;
  width: 100%;
}

%btn-sm {
  font-size: 1.5rem;
  height: 24px;
  line-height: 24px;
}

%btn-lg {
  font-size: 2.5rem;
  height: 44px;
  line-height: 44px;
  padding-top: 3px;
}

%btn-lg-inline {
  @extend %btn-lg;
  width: 100%;

  @media (--screen-sm) {
    width: auto;
    padding-right: 140px;
    padding-left: 140px;
  }
}

.button-action,
%btn-action {
  background: var(--secondary);
  white-space: normal;
  line-height: 1;
  padding-top: 11px;
  padding-bottom: 7px;
  height: auto;
  max-width: 100%;

  &:hover,
  &:focus {
    background: var(--secondaryDarker);
  }
}

%btn-checkbox {
  label {
    @extend button;
    padding-left: 12px;
    padding-right: 12px;
    background-color: var(--disabled);
  }

  input {
    display: none;

    &:checked {
      & + label {
        color: var(--white);
        background-color: var(--secondary);
      }
    }
  }
}
