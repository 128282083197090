.view-display-id-home {
  & .more-link {
    padding-top: 104px;
    clear: left;
    text-align: center;

    & a {
      @extend %btn-lg-inline;
    }
  }
}
