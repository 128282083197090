.view-event-sponsors {
  & .view-content {
    overflow: hidden;
  }

  & .views-field-field-member-logo {
    & a,
    & img {
      display: block;
      margin: 0 auto;
    }
  }

  &.view-display-id-high,
  &.view-display-id-mid,
  &.view-display-id-low {
    & .views-row {
      margin-top: 64px;
    }
  }

  &.view-display-id-featured {
    & img {
      width: 100%;
    }
  }

  &.view-display-id-text {
    @extend .row;
  }

  & .view-grouping {
    @extend .col-xs-12;
    @extend .col-sm-6;

    &:nth-child(2n + 1) {
      clear: left;
    }

    & .views-row {
      margin-top: 20px;
    }
  }

  & .views-field-field-organization-name {
    & a {
      @extend %icon-external-link;
    }
  }
}

#block-views-event-sponsors-featured {
  display: none;

  & + .block {
    margin-top: 0;
  }

  @media (--screen-md) {
    display: block;

    & + .block {
      margin-top: 100px;
    }
  }
}
