%image-overlay {
  font-family: var(--font-family-heading);
  color: var(--white);
  background-color: color(var(--secondary) alpha(-20%));
}

%image-overlay-content {
  padding: 40px 40px 32px;
}

%image-overlay-btn {
  @extend button;
  @extend %image-overlay;
}

.image-overlay {
  @extend %image-overlay;
}

