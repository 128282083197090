html {
  box-sizing: border-box;
  font-size: 10px;

  @media (--screen-sm) {
    font-size: 8.5px;
  }

  @media (--screen-lg) {
    font-size: 9px;
  }

  @media (--screen-lg) {
    font-size: 10px;
  }
}

body {
  font-family: var(--font-family-content);
  color: var(--blackAlt);
  font-size: var(--font-size-primary);
  line-height: var(--line-height-primary);

  /* &::before { */
    /* content: 'XS'; */
    /* position: fixed; */
    /* bottom: 10px; */
    /* left: 10px; */
    /* z-index: 10000; */
    /* color: var(--warning); */
    /* font-weight: bold; */

    /* @media (--screen-sm) { */
      /* content: 'SM'; */
    /* } */

    /* @media (--screen-md) { */
      /* content: 'MD'; */
    /* } */

    /* @media (--screen-lg) { */
      /* content: 'LG'; */
    /* } */

    /* @media (--screen-xl) { */
      /* content: 'XL'; */
    /* } */
  /* } */
}

main {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  color: var(--black);
  font-weight: normal;
  line-height: 1;
  margin-top: 72px;
  margin-bottom: 36px;

  &:first-child {
    margin-top: 0;
  }

  & a {
    font-weight: inherit !important;
  }
}

h1 {
  font-family: var(--font-family-heading);
  font-size: 6.5rem;
  margin-bottom: 64px;

  @media (--screen-sm ) {
    font-size: 8.3rem;
  }
}

h2 {
  font-size: 3.7rem;
  line-height: 1.1;
}

h3 {
  font-family: var(--font-family-heading);
  font-size: 3.5rem;
}

h4 {
  font-weight: bold;
  margin-bottom: 24px;
}

a {
  color: inherit;
  text-decoration: none;

  &.active,
  &:hover,
  &:focus {
    color: var(--primary);
  }
}

p {
  margin: 0;
  margin-bottom: 36px;

  &:empty {
    margin-bottom: 0;
  }
}

ol, ul {
  margin-top: 48px;
  margin-bottom: 48px;
  padding: 0;
  padding-left: 20px;
}

ul {
  & li {
    list-style: square;
  }

  &.inline {
    padding-left: 0;
    margin-top: 12px;
    margin-bottom: 12px;

    & li {
      list-style: none;
      display: inline;
      padding-left: 0;
    }
  }
}

li {
  line-height: 1.3;
  margin-bottom: 24px;
  padding-left: 20px;
}

address {
  font-style: normal;
}

figcaption {
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-secondary);
  font-style: italic;
}

blockquote {
  font-family: var(--font-family-heading);
  font-size: 2.7rem;
  letter-spacing: 0.02em;
}

figure {
  font-family: var(--font-family-heading);
  font-size: 5.5rem;
  letter-spacing: 0.02em;
}

input[type="button"],
input[type="reset"],
input[type="submit"],
button,
.button {
  font-family: var(--font-family-heading);
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  appearance: none;
  font-size: 2.1rem;
  padding-top: 2px;
  padding-right: 32px;
  padding-bottom: 0;
  padding-left: 32px;
  width: auto;
  height: 36px;
  line-height: 36px;
  background-color: var(--tertiary);
  color: var(--white); /* !important; */
  border: none;
  white-space: nowrap;
  text-align: center;

  &:hover,
  &:focus {
    background-color: var(--tertiaryDarker);
    color: var(--white);
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  border: none;
  background-color: var(--tertiaryLighter);
  color: var(--blackAlt);
  border-bottom: 2px solid var(--tertiary);
  padding: 8px 12px 6px;
  font-size: var(--font-size-secondary);
  outline: none !important;
  width: 100%;
  resize: none;

  &.error {
    background-color: #FFEBEE;
    border-bottom-color: var(--warning);
  }

  &:focus {
    color: var(--primaryDarker);
    background-color: #E3F2FD;
    border-bottom-color: var(--primary);
  }
}

label {
  font-weight: bold;
}

img {
  max-width: 100%;
  height: auto;
}

table {
  table-layout: fixed;
  width: 100%;
  margin-bottom: 48px;
}

tr {
  &.even {
    background-color: var(--tertiaryLighter);
  }
}

th {
  text-align: left;
  border-bottom: 1px solid var(--tertiary);
  font-family: var(--font-family-heading);
  text-transform: uppercase;
  letter-spacing: 1px;

  & a {
    color: var(--black);

    &.active {
      color: inherit;
    }
  }

  &.active {
    & a {
      color: var(--primary);
    }
  }
}

th,
td {
  padding: 8px;
  vertical-align: middle;
}
