#header {
  @extend %box-shadow;
  top: 24px;
  left: 0;
  right: 0;
  height: var(--header-height);
  background: var(--primary);
  transition: top 0.2s;

  @media (--screen-md) {
    height: var(--header-height-md);
  }

  @media (--screen-lg) {
    height: var(--header-height-lg);
  }

  @media (--screen-xl) {
    height: var(--header-height-xl);
  }
}
