.region-navigation {
  @extend .wrapper;
  position: absolute;
  right: 0;
  left: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--primary);
    z-index: -1;
  }
}
