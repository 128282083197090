#skip-link {
  @extend .layer-absolute;
  @extend .layer-top;
  top: 0;
  left: 0;
  right: 0;

  & a {
    &:focus {
      display: block;
      margin-top: 140px;
    }
  }
}
