.field-name-field-link-block {
  position: relative;
  overflow: hidden;

  & .field-name-field-image {
    & .background-image-formatter {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
    }
  }

  & .field-name-field-link {
    position: relative;
    z-index: 1;

    & .field-items {
      display: flex;
      flex-flow: row wrap;
      width: calc(100% + 4px);
      transform: translateX(-2px);
    }

    & .field-item {
      flex: 1 1 50%;
      height: 200px;
      border: 2px solid var(--white);
      text-align: center;

      & a {
        display: inline-block;
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 200ms;

        & span {
          @extend %image-overlay;
          @extend %btn-lg;
          line-height: var(--line-height-secondary);
          display: inline-block;
          padding-top: 12px;
          padding-right: 40px;
          padding-bottom: 9px;
          padding-left: 40px;
          height: auto;
          max-width: 80%;
        }

        &:hover {
          background-color: color(var(--primary) alpha(-50%));
        }
      }
    }
  }
}
