@keyframes scroll-down {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  10% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}

.view-homepage-slideshow {
  & .views-field-field-slide-image {
    & .field-content {
      opacity: 0;
      transition: opacity 1s;
      background-size: cover;
      background-position: 50%;
      background-color: var(--tertiaryLighter);
    }
  }

  & .views-field-field-slide-message {
    @extend .wrapper;
    position: absolute;
    bottom: 134px;
    max-width: 100%;

    @media (--screen-sm) {
      right: 0;
      bottom: 89px;
      max-width: 40%;
    }

    @media (--screen-md) {
      bottom: 109px;
      max-width: 45%;
    }

    & a {
      @extend %image-overlay;
      @extend %image-overlay-content;
      @extend .pull-right;
      font-size: 4.1rem;

      @media (--screen-sm) {
        font-size: 4.9rem;
      }
    }
  }

  & .views-field-field-slide-link-1 {
    @extend .wrapper;
    right: 0;
    bottom: 98px;

    @media (--screen-sm) {
      bottom: 53px;
    }

    @media (--screen-md) {
      bottom: 73px;
    }

    & .field-content {
      @extend %image-overlay;
    }

    & a {
      @extend button;
      @extend .pull-right;
      background-color: inherit !important;
    }
  }

  & .views-slideshow-scroll-down {
    display: none;
    position: absolute;
    z-index: 1000;
    left: 50%;
    bottom: 53px;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 300ms;
    background: transparent;

    @media (--screen-sm) {
      display: block;
    }

    @media (--screen-md) {
      bottom: 73px;
    }

    & button {
      @extend %icon-scroll;
      font-size: 0;
      color: transparent;
      padding: 0;
      background: transparent;
      outline: none;
      text-align: center;

      &::before {
        display: block;
        animation: scroll-down 1.5s linear infinite;
        font-size: 3.5rem;
        color: color(var(--white) alpha(90%));
      }
    }
  }
}
