form {
  & .filter-wrapper {
    &:empty {
      display: none;
    }
  }
}

.form-actions {
  margin-top: 48px;

  input {
    outline: none;

    &:focus,
    &:hover {
      background-color: var(--primary);
    }
  }

  &::after {
    content: '* Denotes required field.';
    display: block;
    margin-top: 36px;
    font-size: var(--font-size-secondary);
    line-height: var(--font-size-secondary);
    font-style: italic;
    color: var(--warning);
  }
}

.form-item {
  &,
  &.form-type-textarea {
    margin-bottom: 36px;
  }

  & label {
    display: block;
    margin-bottom: 8px;
  }

  & .description {
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
    font-style: italic;
    margin-top: 4px;
  }

  & .form-item {
    margin-bottom: 0;
  }

  &.form-type-checkbox {
    position: relative;

    & input {
      float: left;
      height: 0;
      width: 0;
      overflow: hidden;

      & ~ label {
        font-size: var(--font-size-secondary);
        line-height: var(--line-height-secondary);
        cursor: pointer;

        &::before {
          content: '';
          display: inline-block;
          outline: 2px solid var(--tertiaryDarker);
          border: 2px solid var(--tertiaryLighter);
          background-color: var(--tertiaryLighter);
          width: 18px;
          height: 18px;
          vertical-align: middle;
          margin-right: 8px;
          transform: translateY(-2px);
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          transform: rotate(45deg);
          color: var(--tertiaryDarker);
          font-size: 3.5rem;
          line-height: 0.6;
          font-weight: normal;
        }
      }

      &:focus,
      &:active {
        & ~ label {
          &::before {
            background-color: var(--primaryTouch);
            border-color: var(--primaryTouch);
            outline-color: var(--primary);
          }

          &::after {
            color: var(--primary);
          }
        }
      }

      &:checked {
        & ~ label {
          &::after {
            content: '+';
          }
        }
      }
    }
  }

  & + .filter-wrapper {
    font-size: var(--font-size-secondary);
    margin-top: -36px;

    & .tips {
      & li {
        margin-bottom: 4px;
      }
    }
  }
}

.form-required {
  font-size: var(--font-size-secondary);
  color: var(--warning);
}

.webform-confirmation {
  & + .links {
    & a {
      @extend button;
      @extend %btn-action;
    }
  }
}
