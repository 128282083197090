.region-sidebar {
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-secondary);

  & .block {
    margin-top: 100px;

    &:first-child {
      margin-top: 0;
    }
  }

  & .block-title {
    @extend h3;
    font-size: 3.1rem;
    margin-top: 0;
    margin-bottom: 32px;

    @media (--screen-md) {
      white-space: nowrap;
    }
  }

  & .views-row {
    @extend %separator-list;
  }

  & .views-field-title {
    @extend %list-title;
  }

  & .views-field-created,
  & .views-field-created-time {
    margin-top: 8px;
  }

  & .view-employment-opportunities {
    & .views-field-field-position-title {
      @extend %list-title;
    }
  }

  & .view-fact {
    & .views-field-field-statistic {
      & a {
        padding-top: 40px;
      }
    }

    & .views-field-field-fact {
      & a {
        padding-bottom: 40px;
      }
    }

    & .views-field-nothing {
      & a {
        font-size: var(--font-size-secondary);
      }
    }
  }

  & .more-link {
    margin-top: 48px;

    & a {
      @extend %btn-full;
    }
  }
}
