.sharethis-wrapper,
.sharethis-buttons {
  margin-bottom: 36px;
  overflow: hidden;

  & span {
    float: left;
    height: 32px;
    padding-right: 12px;
    padding-left: 12px;
    color: var(--white);
    cursor: pointer;
    background: var(--black);

    &::before {
      vertical-align: middle;
      font-size: 1.7rem;
    }

    &::after {
      text-transform: uppercase;
      font-family: sans-serif;
      font-size: 1.2rem;
      letter-spacing: 1px;
      vertical-align: middle;
      margin-left: 8px;
    }
  }

  & .st_facebook_custom {
    @extend %icon-facebook-boxed;
    background-color: #365b96;
    border-radius: 8px 0 0 8px;

    &::after {
      content: 'Share';
    }
  }

  & .st_twitter_custom {
    @extend %icon-twitter;
    background-color: #00afeb;

    &::after {
      content: 'Tweet';
    }
  }

  & .st_googleplus_custom {
    @extend %icon-google-plus;
    background-color: #e84538;
    border-radius: 0 8px 8px 0;
  }

  & .st_email_custom {
    @extend %icon-mail;
    border-radius: 8px 0 0 8px;
    margin-left: 24px;
  }

  & .st_print_custom {
    @extend %icon-print;
    border-left: 1px solid var(--blackAlt);
    border-radius: 0 8px 8px 0;
  }
}
