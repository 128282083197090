.view-people {
  overflow: hidden;

  & .views-row {
    position: relative;
    overflow: hidden;
  }

  & img {
    display: block;
  }

  & .views-field {
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);

    @media (--screen-xs) {
      font-size: var(--font-size-primary);
      line-height: var(--line-height-primary);
    }
  }

  & .views-field-title {
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
  }

  & .views-field-field-headshot {
    float: left;
    margin-right: 30px;
    width: 30%;

    @media (--screen-xs) {
      width: 200px;
    }
  }

  & .views-field-field-email {
    clear: left;
    width: 100%;
    padding-top: 16px;

    & a {
      width: 100%;
    }

    @media (--screen-xs) {
      position: absolute;
      bottom: 0;
      left: 230px;

      & a {
        width: auto;
      }
    }

    & a {
      @extend button;
      @extend %btn-action;
    }
  }

  &.view-display-id-board {
    & .view-content {
      @extend .row;
    }

    & .view-grouping {
      @extend .col-xs-12;
      @extend .col-sm-6;
    }
  }

  &.view-display-id-single {
    margin-bottom: 64px;

    & .views-field-field-headshot {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
      float: none;

      & img {
        width: 100%;
      }

      @media (--screen-xs) {
        width: 200px;
        margin-right: 30px;
        margin-bottom: 0;
        float: left;
      }

      @media (--screen-sm) {
        width: 300px;
      }
    }

    & .views-field-field-email {
      @media (--screen-sm) {
        left: 330px;
      }
    }
  }

  @media (--screen-sm) {
    &.view-display-id-executives {
      & .views-field-title {
        & a {
          float: left;

          &::after {
            content: ',';
            margin-right: 4px;
          }
        }
      }
    }
  }
}
