.block__section {
  .block-title {
    font-size: 4.1rem;
    text-align: center;
    margin-bottom: 92px;

    @media (--screen-sm) {
      font-size: 4.9rem;
    }

    @media (--screen-md) {
      margin-bottom: 124px;
    }
  }
}
