.region-members-nav {
  top: -24px;
  left: 0;
  right: 0;
  height: 24px;
  background: var(--primaryDarker);

  & .block-title {
    display: none;
  }

  & .block-content {
    @extend .pull-right;

    & li {
      @extend .pull-left;
    }

    & [type="submit"],
    & [type="button"],
    & a {
      @extend .pull-left;
      @extend button;
      @extend %btn-sm;
      background-color: transparent;
      color: var(--primaryTouch);
      width: 128px;
      padding-left: 0;
      padding-right: 0;

      &:hover,
      &:focus {
        background-color: var(--primary900);
        color: var(--white);
      }

      &.active-trail {
        color: var(--white);
      }
    }

    & a {
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
