.view-news,
.view-recent-updates {
  & h3 {
    @extend %heading-teaser;
  }

  & img {
    width: 100%;
    height: auto;
  }

  & p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  & .view-content {
    & .views-row {
      margin-top: 104px;

      &:first-child {
        margin-top: 0 !important;
      }
    }
  }

  &.view-display-id-home {
    & .view-content {
      @extend .row;

      & .views-row {
        margin-top: 112px;
      }

      & .col-right {
        margin-top: 112px;

        @media (--screen-sm) {
          margin-top: 0;
        }
      }
    }
  }

  & .views-row-news {
    & .views-field-nothing {
      & a {
        @extend %link-standard;
      }
    }
  }
}
