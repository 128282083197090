.block-addp-members-only {
  display: none;

  & .block-title {
    display: none;
  }

  & .block-content {
    text-align: center;
  }

  & .form-submit {
    @extend %btn-lg;
    @extend %btn-action;
    margin-bottom: 24px;
  }

  & a {
    @extend %link-standard;
    font-size: var(--font-size-secondary);
  }

  .view-mode-members_only + & {
    display: block;
  }
}
