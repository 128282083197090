.field-name-field-blurbs {
  & h3 {
    margin-top: 32px;
    margin-bottom: 36px;
  }

  & .field-collection-item-field-blurbs {
    text-align: center;
  }

  & .field-item {
    & .entity {
      margin-top: 64px;

      @media (--screen-sm) {
        margin-top: 0;
      }
    }

    &:first-child {
      & .entity {
        margin-top: 0;
      }
    }
  }

  & .field-name-field-description {
    text-align: left;
    padding-left: 20px;

    & p {
      line-height: 1.33;
      margin: 0;
    }
  }
}
