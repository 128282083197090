.callout {
  background-color: var(--secondaryAlt);
  color: var(--white);
  margin-top: 64px;
  margin-right: -var(--gutter);
  margin-bottom: 64px;
  margin-left: -var(--gutter);
  padding-top: 44px;
  padding-right: var(--gutter);
  padding-bottom: 32px;
  padding-left: var(--gutter);
  line-height: 1;
  position: relative;

  & a {
    color: var(--white) !important;
    @extend %link-standard;
  }

  @media (--screen-sm) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (--screen-md) {
    background-image: url(../../img/point.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 80px 100%;
    padding-left: 120px;
    margin-left: -80px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 1000px;
      background-color: var(--secondary);
    }
  }
}
