%heading-footer {
  font-size: var(--font-size-primary);
  line-height: var(--line-height-primary);
  margin-bottom: 48px;
}

%heading-teaser {
  font-size: 4.5rem;
  margin-top: 24px;
  margin-bottom: 24px;
}

%heading-compact {
  margin-bottom: 0;
}
