%navigation-link {
  font-weight: bold;
  font-size: 1.8rem;
  color: var(--white);
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  padding-right: var(--gutter-md);
  padding-left: var(--gutter-md);

  @media (--screen-xl) {
    padding-right: var(--gutter);
    padding-left: var(--gutter);
  }

  &::after {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

%navigation-icon {
  margin: 0;
  text-indent: -9999px;
  overflow: hidden;
  width: 64px;

  &::before {
    text-indent: 0;
    float: left;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -1px;
  }
}

%navigation-menu {
  /**
   * Ensure navigation items can span the full width and not be contained
   * within the contextual-links-region.
   */
  position: static;

  & .menu {
    white-space: nowrap;
  }

  & .menu__item,
  & .menu__link {
    display: inline-block;
    text-align: center;
  }

  & .block-content {
    & > .menu {
      height: 100%;

      & > .menu__item {
        height: 100%;
        float: left;

        @media (--screen-md) {
          padding: 0;
        }

        & > .menu__link {
          @extend %navigation-link;
        }

        &:hover,
        &:focus {
          & > .menu__link {
            background-color: var(--primaryLighter);
          }
        }

        &.is-active-trail {
          @media (--screen-md) {
            &::after {
              content: '';
              position: absolute;
              border-bottom: 4px solid var(--primaryDarker);
              left: 0;
              right: 0;
              bottom: 0;
            }
          }

          & > .menu {
            display: block;
          }

          & > .menu__link,
          & > .block-title {
            background-color: var(--primaryDarker);
          }
        }
      }

      & .menu {
        display: none;
      }
    }
  }
}
