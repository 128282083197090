#block-webform-client-block-62 {
  @extend .interruptor-dark;

  .block-title {
    @extend .interruptor-title-dark;
  }

  & .block-content {
    @extend .interruptor-main;
    padding-right: 0;
  }

  & form {
    @extend .interruptor-content-wrapper;

    & > div {
      display: table;
      left: 50%;
      position: relative;
      transform: translateX(-50%);

      @media (--screen-md) {
        width: 75%;
      }
    }

    & .webform-component {
      display: table-cell;
      vertical-align: middle;
    }

    & input {
      height: 48px;
      line-height: 48px;
      outline: none;

      &[type="submit"] {
        background-color: var(--primary);

        &:focus,
        &:hover {
          background-color: var(--primaryDarker);
        }
      }

      &[type="text"],
      &[type="email"] {
        border: none;
        outline: none;
        background: transparent;
        color: var(--white);
        font-size: 2.5rem;
        padding: 0;

        @media (--screen-md) {
          border-bottom: 2px solid var(--white);
        }
      }

      &::placeholder {
        color: color(var(--white) alpha(66%));
      }
    }

    & .form-actions {
      display: table-cell;
      width: 1px;
      padding-left: 20px;

      &::after {
        content: none;
      }
    }
  }
}
