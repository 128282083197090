.search-results {
  margin-top: -64px;

  & .block-title {
    margin-bottom: 80px;
  }

  & .search-results-list {
    padding: 0;
    list-style: none;
  }

  & .search-result {
    padding: 0;
    margin-bottom: 80px;
  }

  & .search-snippet-info {
    padding: 0;
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
  }
}
