.view-sidebar-contact {
  & img {
    display: block;
  }

  & .views-row {
    position: relative;
    overflow: hidden;
  }

  & .views-field-field-headshot {
    width: 35%;
    float: left;
    margin-right: var(--gutter);

    & ~ .views-field-nothing {
      position: absolute;
      left: calc(35% + var(--gutter));
      right: 0;
      bottom: 0;
    }
  }

  & .views-field-field-phone {
    margin-top: 4px;
  }

  & .views-field {
    font-size: var(--font-size-primary);

    @media (--screen-md) {
      font-size: var(--font-size-secondary);
    }
  }

  & .views-field-nothing {
    & a {
      @extend button;
      @extend %btn-full;
      color: var(--white);
      margin-top: 32px;
      font-size: inherit;
    }
  }
}
