#block-views-save-the-date-block {
  @extend .interruptor-light;

  & .block-title {
    @extend .interruptor-title-light;
  }

  & .block-content {
    @extend .interruptor-main;
  }

  & .view {
    @extend .interruptor-content-wrapper;
  }

  & .views-row {
    @extend .interruptor-content;
  }

  @media (--screen-sm) {
    & .views-field-title {
      & a {
        @extend %icon-info;

        &::before {
          display: inline-block;
          width: 32px;
          height: 32px;
          vertical-align: middle;
          margin-right: 20px;
        }
      }
    }
  }
}
