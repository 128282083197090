.interruptor {
  overflow: hidden;

  background: var(--blackAlt);
  color: var(--white);

  @media (--screen-md) {
    height: var(--interruptor-height-md);
  }

  @media (--screen-lg) {
    height: var(--interruptor-height-lg);
  }

  @media (--screen-xl) {
    height: var(--interruptor-height-xl);
  }
}

.interruptor-light {
  @extend .interruptor;
  background: var(--secondaryAlt);
}

.interruptor-dark {
  @extend .interruptor;
  background: var(--primaryAlt);
}

.interruptor-title {
  @extend h3;
  @extend .col-xs-12;
  @extend .col-md-6;

  position: relative;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  background: var(--black);
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  color: var(--white);
  padding-top: 24px;
  padding-bottom: 8px;
  font-size: 8rem;

  @media (--screen-sm) {
    font-size: 10.5rem;
  }

  @media (--screen-md) {
    padding-top: 54px;
  }

  @media (--screen-lg) {
    padding-top: 56px;
    font-size: 11rem;
  }

  @media (--screen-xl) {
    padding-top: 60px;
    font-size: 13.2rem;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    z-index: 10;
    left: 100%;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;

    @media (--screen-md) {
      border-left: 64px solid var(--black);
      border-top-width: calc(var(--interruptor-height-md) / 2);
      border-bottom-width: calc(var(--interruptor-height-md) / 2);
    }

    @media (--screen-lg) {
      border-top-width: calc(var(--interruptor-height-lg) / 2);
      border-bottom-width: calc(var(--interruptor-height-lg) / 2);
    }

    @media (--screen-xl) {
      border-top-width: calc(var(--interruptor-height-xl) / 2);
      border-bottom-width: calc(var(--interruptor-height-xl) / 2);
    }
  }
}

.interruptor-title-light {
  @extend .interruptor-title;
  background: var(--secondary);

  &::after {
    border-left-color: var(--secondary);
  }
}

.interruptor-title-dark {
  @extend .interruptor-title;
  background: var(--primary);

  &::after {
    border-left-color: var(--primary);
  }
}

.interruptor-main {
  @extend .col-xs-12;
  @extend .col-md-6;

  font-size: 3.1rem;
  font-family: var(--font-family-content);
  font-weight: bold;
  line-height: 1;
  display: table;
  table-layout: fixed;
  height: 100%;

  @media (--screen-xs-only) {
    font-size: 2.4rem;
  }

  @media (--screen-md) {
    border-left: 64px solid transparent;
  }
}

.interruptor-content-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.interruptor-content {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  float: left;
  clear: left;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (--screen-md) {
    padding-top: 0;
    padding-bottom: 0;
  }

  & a {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--white) !important;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
