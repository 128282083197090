.footer-post {
  @extend .wrapper;
  position: relative;
  text-align: center;
  background: var(--black);
  color: var(--white);
  font-size: 1.2rem;
  padding-top: 20px;
  padding-bottom: 20px;
}
