.view-tweets {
  font-size: var(--font-size-secondary);
  line-height: var(--line-height-secondary);

  & .views-row {
    @extend %separator-list;
    position: relative;
    padding-left: 84px;
  }

  & .views-two-column {
    & .views-column-right {
      @extend %separator;
    }

    @media (--screen-sm) {
      & .views-column-right {
        @extend %separator-clear;

        &::before {
          content: none;
        }
      }
    }
  }

  & .views-field-profile-image-url {
    position: absolute;
    left: 0;
  }

  & .views-field-name {
    @extend %list-title;
  }

  & .views-field-text {
    & a {
      text-decoration: underline;
    }
  }

  & .views-field-created-time {
    @extend %list-date;
    margin-top: 8px;
  }

  &.view-display-id-twitter_short {
    & .views-row {
      padding-left: 68px;
    }
  }
}

