.views-two-column {
  & .views-column-right {
    margin-top: 64px;
  }

  @media (--screen-sm) {
    & .views-column-right {
      margin-top: 0;
    }
  }
}
