.layer {
  position: relative;
}

.layer-absolute {
  position: absolute;
}

.layer-fixed {
  will-change: transform;
  position: fixed;
}

.layer-top {
  z-index: 100;
}

.layer-bottom {
  z-index: 1;
}

.width-full {
  width: 100% !important;
}

.height-full {
  height: 100% !important;
}

.height-screen {
  min-height: 380px;
  max-height: 1000px;

  @media (--screen-md) {
    min-height: 640px;
    max-height: 2000px;
  }
}

.wrapper {
  padding-right: calc(var(--gutter-sm) * 2);
  padding-left: calc(var(--gutter-sm) * 2);

  @media (--screen-md) {
    padding-right: calc(var(--gutter-md) * 2);
    padding-left: calc(var(--gutter-md) * 2);
  }

  @media (--screen-lg) {
    padding-right: calc(var(--gutter) * 2);
    padding-left: calc(var(--gutter) * 2);
  }
}
