.view-fact {
  overflow: hidden;

  & .views-field {
    & .field-content {
      background-color: var(--primaryDarker);
    }

    & a {
      display: block;
      font-family: var(--font-family-heading);
      color: var(--white);
    }
  }

  & .views-row-even,
  & .views-row-fact-even {
    & .field-content {
      background-color: var(--primaryLighter);
    }
  }

  & .views-field-field-statistic {
    & a {
      text-align: center;
      padding-top: calc(var(--gutter-sm) * 2);
      white-space: nowrap;
      line-height: 1;
      word-spacing: -0.02em;
      letter-spacing: -0.01em;
      font-size: 15rem;

      @media (--screen-sm) {
        font-size: 15rem;
        padding-top: calc(var(--gutter-md) * 2);
      }

      @media (--screen-md) {
        font-size: 24rem;
        margin-bottom: -20px;
        padding-top: calc(var(--gutter) * 2);
      }
    }
  }

  & .field-statistic-percentage {
    font-size: 72.5%;
    vertical-align: top;
  }

  & .field-statistic-text {
    font-size: 72.5%;
  }

  & .views-field-field-fact {
    & a {
      padding-right: calc(var(--gutter-sm) * 2);
      padding-bottom: calc(var(--gutter-sm) * 2);
      padding-left: calc(var(--gutter-sm) * 2);
      font-size: 4.5rem;
      line-height: 1;

      @media (--screen-sm) {
        padding-right: calc(var(--gutter-md) * 2);
        padding-bottom: calc(var(--gutter-md) * 2);
        padding-left: calc(var(--gutter-md) * 2);
      }

      @media (--screen-md) {
        padding-right: calc(var(--gutter) * 2);
        padding-bottom: calc(var(--gutter) * 2);
        padding-left: calc(var(--gutter) * 2);
      }
    }
  }

  & .views-field-nothing {
    & a {
      @extend .pull-right;
      @extend button;
      background: inherit !important;
    }
  }

  .region-sidebar & {
    & .views-field-field-statistic {
      & a {
        font-size: 15.5rem;
        margin-bottom: -20px;
      }
    }

    & .views-field-field-fact {
      & a {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 3rem;
      }
    }
  }
}
