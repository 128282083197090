#block-system-main-menu {
  @extend %navigation-menu;

  & .block-title {
    @extend %navigation-icon;
    @extend %navigation-link;
    @extend %icon-menu;

    @media (--screen-md) {
      display: none;
    }

    &::before {
      @media (--screen-md) {
        content: none;
      }
    }

    &:hover,
    &:focus {
      background-color: var(--primaryLighter);
    }
  }

  &.open {
    & .block-title {
      background-color: var(--primaryDarker);
    }
  }

  & .block-content {
    & > .menu {
      display: none;

      & .menu {
        @extend .wrapper;
        font-size: 0; /* eliminate gaps between link items */
        background-color: var(--white);
        text-align: right;
        z-index: -2;

        & .menu__link {
          padding-right: var(--gutter-md);
          padding-left: var(--gutter-md);

          @media (--screen-md) {
            line-height: 64px;
          }

          @media (--screen-xl) {
            padding-right: var(--gutter);
            padding-left: var(--gutter);
          }
        }
      }

      @media (--screen-md) {
        display: block;

        & .menu {
          @extend %box-shadow;
          position: absolute;
          top: 100%;
          right: 0;
          left: 0;
          transform: translateY(0);
          opacity: 1;
          transition: opacity 0.3s, transform 0.3s;
          padding-right: 168px;
        }
      }

      @media (--screen-lg) {
        & .menu {
          padding-right: 188px;
        }
      }
    }
  }

  &.open {
    & .menu__item,
    & .menu__link {
      display: block;

      @media (--screen-md) {
        display: inline-block;
      }
    }

    & .menu__link {
      padding-top: 8px;
      padding-bottom: 8px;

      @media (--screen-md) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    & .block-content {
      & > .menu {
        @extend %box-shadow;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: auto;
        background: var(--primaryAlt);
        border-top: 4px solid var(--primaryDarker);
        border-bottom: 4px solid var(--primaryDarker);

        @media (--screen-md) {
          box-shadow: none;
          border-top: none;
          border-bottom: none;
          position: static;
          height: 100%;
          background: transparent;
        }

        & > .menu__item {
          height: auto;
          float: none;

          @media (--screen-md) {
            height: 100%;
            float: left;
          }
        }
      }
    }
  }
}
