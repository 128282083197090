.ajax-progress {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: color(var(--white) alpha(50%));

  & .throbber {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 128px;
    height: 15px;
    background-image: url(../../img/load.gif);
  }
}
