#logo {
  float: left;
  top: 16%;
  height: 68%;
  padding-right: var(--gutter-sm);

  @media (--screen-md) {
    padding-right: var(--gutter-md);
  }

  @media (--screen-lg) {
    padding-right: var(--gutter);
  }

  & img {
    width: auto;
    height: 100%;
  }
}
