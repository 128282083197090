.messages {
  color: var(--white);
  text-align: center;
  font-size: var(--font-size-primary);
  line-height: var(--line-height-primary);
  font-style: italic;
  padding: 17px 30px;

  &.status {
    background-color: var(--statusAlt);
    color: var(--status);
  }

  &.warning {
    background-color: var(--warningAlt);
    color: var(--warning);
  }

  &.error {
    background-color: var(--errorAlt);
    color: var(--error);
  }

  & .messages__list {
    margin: 0;
    padding: 0;
  }

  & a {
    text-decoration: underline;
  }

  & p,
  & li {
    margin: 0;
    list-style: none;
  }
}
