.scrolling {
  & #block-sitewide-site-name-block {
    opacity: 0;
  }

  & .views-slideshow-scroll-down {
    opacity: 0 !important;
    pointer-events: none !important;
  }
}

.scrolling-down {
  & #block-system-main-menu {
    & .block-content {
      & .menu {
        & .menu {
          @media (--screen-md) {
            transform: translateY(-100%);
            opacity: 0;
          }
        }
      }
    }
  }

  & #header {
    top: 0;
  }
}
