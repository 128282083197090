.field-name-field-image-gallery {
  text-align: center;
  margin-bottom: 36px;

  @media (--screen-sm) {
    text-align: left;
    float: left;
    width: 200px;
    margin-right: 40px;
    margin-bottom: 20px;
  }

  & .field-item {
    margin-top: 64px;

    &:first-child {
      margin-top: 0
    }
  }
}
