.node {
  & .field-name-body {
    & p,
    & ul,
    & ol {
      & a {
        &:not(.btn-action) {
          @extend %link-underlined;
        }
      }
    }

    & a {
      &.btn-action {
        @extend button;
        @extend %btn-action;
      }
    }
  }

  & .field-name-field-source {
    font-style: italic;
    font-size: var(--font-size-secondary);
    margin-top: 20px;

    & .field-label {
      margin-right: 4px;
      font-weight: normal;

      &:after {
        content: ':';
      }
    }

    a {
      @extend %link-underlined;
    }
  }

  & .field-name-field-date {
    margin-top: -24px;
    margin-bottom: 64px;
  }

  & .field-name-field-main-image {
    margin-bottom: 72px;
  }

  & .field-name-field-image-caption {
    @extend figcaption;

    & a {
      @extend %link-standard;
    }
  }

  & .field-name-field-registration-link {
    & a[href] {
      @extend button;
      @extend %btn-action;
      @extend %btn-lg;
    }
  }

  & .field-name-field-category,
  & .field-name-field-committee-type,
  & .field-name-field-subtitle,
  & .field-name-field-role,
  & .field-name-taxonomy-forums {
    position: absolute;
    top: -32px;
    color: var(--black);
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
  }

  & .field-name-field-role {
    & .field-item {
      display: inline;

      &::after {
        content: ',';
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }

  & blockquote {
    margin: 0;
    margin-top: 64px;
    margin-bottom: 64px;
    padding-right: 40px;
    padding-left: 36px;
    border-left: 4px solid var(--secondary);

    & p {
      margin: 0;
    }
  }

  & .callout {
    clear: left;
  }

  &.node-unpublished {
    background-color: transparent;
  }

  &.view-mode-members_only {
    & .field-name-body {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 120px;
        background-image: linear-gradient(0deg, var(--white), transparent);
      }
    }
  }
}
