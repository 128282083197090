.addp-contrast {
  .page {
    color: var(--contrast-black);

    h1, h2, h3, h4, h5, h6,
    .footer-bottom,
    .pager .pager-item a, .pager .pager-item span, .pager .pager-ellipsis a, .pager .pager-ellipsis span, .pager .pager-nav-item a, .pager .pager-nav-item span {
      color: var(--contrast-black);
    }

    a.active, a:hover, a:focus,
    button.active, button:hover, button:focus,
    input[type="submit"].active, input[type="submit"]:hover, input[type="submit"]:focus {
      text-decoration: underline;
    }

    #block-menu-menu-utility-menu .block-content > .menu > .menu__item:hover > .menu__link, #block-menu-menu-utility-menu .block-content > .menu > .menu__item:focus > .menu__link, #block-system-main-menu .block-content > .menu > .menu__item:hover > .menu__link, #block-system-main-menu .block-content > .menu > .menu__item:focus > .menu__link {
      background-color: transparent;
      text-decoration: underline;
    }

    #header,
    .region-navigation:before,
    input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, textarea:focus,
    .interruptor-title-dark, #block-webform-client-block-62 .block-title,
    #block-webform-client-block-62 form input[type="submit"],
    #cboxContent, .cboxIframe, #cboxClose:before,
    .form-actions input:focus, .form-actions input:hover,
    .footer-post,
    .field-name-field-application-url a[href], .webform-confirmation + .links a, .node .field-name-body a.btn-action, .node .field-name-field-registration-link a[href], .view-constant-contact-sidebar .views-field-title a, .view-contact .views-field-title a, .view-people .views-field-field-email a, .view-event-details .views-field-field-registration-link a, .view-employment-opportunities .attachment-after .views-field-nothing a, .view-featured-event .views-field-nothing a,
    .view-fact .views-field .field-content,
    button,
    .image-overlay, #cboxTitle, .view-homepage-slideshow .views-field-field-slide-message a, .view-homepage-slideshow .views-field-field-slide-link-1 .field-content,
    .interruptor-title-light, #block-views-save-the-date-block .block-title, #block-views-now-available-block .block-title,
    input[type="button"], input[type="reset"], input[type="submit"], button, .view .more-link a, .field-name-field-application-url a[href], .webform-confirmation + .links a, .node .field-name-body a.btn-action, .node .field-name-field-registration-link a[href], .view-constant-contact-sidebar .views-field-title a, .view-contact .views-field-title a, .view-homepage-slideshow .views-field-field-slide-link-1 a, .view-fact .views-field-nothing a, .view-people .views-field-field-email a, .view-events .form-type-bef-checkbox label, .view-event-details .views-field-field-registration-link a, .view-employment-opportunities .attachment-after .views-field-nothing a, .view-featured-event .views-field-nothing a, .view-sidebar-contact .views-field-nothing a,
    #block-system-main-menu.open .block-content > .menu {
      background: var(--contrast-black);
    }

    #addp_scroll_down button {
      background: transparent;
    }

    .interruptor-dark, #block-webform-client-block-62,
    .interruptor-light, #block-views-save-the-date-block, #block-views-now-available-block {
      border-top: 2px solid var(--contrast-black);
      border-bottom: 2px solid var(--contrast-black);
      color: var(--contrast-black);
      background: var(--contrast-white);
    }

    #block-webform-client-block-62 form input[type="text"], #block-webform-client-block-62 form input[type="email"],
    #block-webform-client-block-62 form input::placeholder,
    .interruptor-content a, #block-views-save-the-date-block .views-row a, #block-views-now-available-block .views-row a {
      color: var(--contrast-black) !important;
    }

    input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, textarea:focus {
      color: var(--contrast-white);
      border-bottom-color: var(--contrast-black);
    }

    #block-webform-client-block-62 form input[type="text"], #block-webform-client-block-62 form input[type="email"] {
      border-bottom-color: var(--contrast-black);
    }

    .form-item.form-type-checkbox input:focus ~ label:before, .form-item.form-type-checkbox input:active ~ label:before,{
      outline-color: var(--contrast-black);
    }

    .interruptor-title-light:after, #block-views-save-the-date-block .block-title:after, #block-views-now-available-block .block-title:after,
    .interruptor-title-dark:after, #block-webform-client-block-62 .block-title:after {
      border-left-color: var(--contrast-black);
    }

    #block-system-main-menu.open .block-title,
    #block-system-main-menu .block-title:hover, #block-system-main-menu .block-title:focus,
    #block-menu-menu-utility-menu .block-content > .menu > .menu__item.is-active-trail > .menu__link, #block-menu-menu-utility-menu .block-content > .menu > .menu__item.is-active-trail > .block-title, #block-system-main-menu .block-content > .menu > .menu__item.is-active-trail > .menu__link, #block-system-main-menu .block-content > .menu > .menu__item.is-active-trail > .block-title {
      background: var(--contrast-white);
      color: var(--contrast-black);
    }

    #block-system-main-menu.open .block-content > .menu {
      border-color: transparent;
    }

    #block-menu-menu-utility-menu .block-content > .menu > .menu__item.is-active-trail:after, #block-system-main-menu .block-content > .menu > .menu__item.is-active-trail:after {
      display: none;
    }
  }
}
