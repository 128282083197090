.view-events {
  & .views-submit-button {
    margin: 0;
  }

  & .form-type-bef-checkbox {
    @extend %btn-checkbox;
    float: left;
    margin-right: 1px;
    margin-bottom: 1px;
  }

  & .views-field-field-date {
    @extend %list-date;
  }
}
