.view-all-facts {
  .views-row {
    background: var(--primaryDarker);
    color: var(--white);
    padding: 48px 60px 48px;
    overflow: hidden;
  }

  .views-row-even {
    background-color: var(--primaryLighter);
  }

  .views-field-title {
    @extend h3;
    font-size: 5.5rem;
    color: inherit;
  }

  .views-field-body {
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
  }

  .views-field-field-source {
    line-height: 1;
    text-align: right;
    margin-top: 20px;
    max-width: 75%;
    float: right;

    .views-label {
      opacity: 0.6;

      &::after {
        content: ':';
      }
    }

    .field-content {
      a {
        text-decoration: underline;
        color: var(--white);
      }
    }

    .views-label,
    .field-content {
      display: inline;
      font-size: var(--font-size-secondary);
      line-height: var(--line-height-secondary);
      font-style: italic;
    }
  }
}
