.views-exposed-widget {
  padding: 0;
  margin-bottom: 12px;
  width: 100%;

  & > label {
    @extend h3;
    float: left;
    padding-right: 12px;
    padding-left: 0;
    margin-right: 1px;
    background: var(--white);
    color: var(--blackAlt);
  }
}
