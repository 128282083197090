.view-featured-event {
  & .views-field-field-date {
    @extend %list-date;
  }

  & .views-field-nothing {
    a {
      @extend button;
      @extend %btn-full;
      @extend %btn-action;
      margin-top: 48px;
    }
  }
}
