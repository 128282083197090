@font-face {
    font-family: 'Bebas Neue';
    src: url('../../fonts/bebas-neue.eot');
    src: url('../../fonts/bebas-neue.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/bebas-neue.woff2') format('woff2'),
         url('../../fonts/bebas-neue.woff') format('woff'),
         url('../../fonts/bebas-neue.ttf') format('truetype'),
         url('../../fonts/bebas-neue.svg#bebasneue') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: url('../../fonts/lora-bold.eot');
    src: url('../../fonts/lora-bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/lora-bold.woff2') format('woff2'),
         url('../../fonts/lora-bold.woff') format('woff'),
         url('../../fonts/lora-bold.ttf') format('truetype'),
         url('../../fonts/lora-bold.svg#lorabold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: url('../../fonts/lora-bolditalic.eot');
    src: url('../../fonts/lora-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/lora-bolditalic.woff2') format('woff2'),
         url('../../fonts/lora-bolditalic.woff') format('woff'),
         url('../../fonts/lora-bolditalic.ttf') format('truetype'),
         url('../../fonts/lora-bolditalic.svg#lorabold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Lora';
    src: url('../../fonts/lora-italic.eot');
    src: url('../../fonts/lora-italic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/lora-italic.woff2') format('woff2'),
         url('../../fonts/lora-italic.woff') format('woff'),
         url('../../fonts/lora-italic.ttf') format('truetype'),
         url('../../fonts/lora-italic.svg#loraitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lora';
    src: url('../../fonts/lora-regular.eot');
    src: url('../../fonts/lora-regular.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/lora-regular.woff2') format('woff2'),
         url('../../fonts/lora-regular.woff') format('woff'),
         url('../../fonts/lora-regular.ttf') format('truetype'),
         url('../../fonts/lora-regular.svg#loraregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nothing You Could Do';
    src: url('../../fonts/nothing-you-could-do-bold.eot');
    src: url('../../fonts/nothing-you-could-do-bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/nothing-you-could-do-bold.woff2') format('woff2'),
         url('../../fonts/nothing-you-could-do-bold.woff') format('woff'),
         url('../../fonts/nothing-you-could-do-bold.ttf') format('truetype'),
         url('../../fonts/nothing-you-could-do-bold.svg#nothing_you_could_do_bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Icon';
    src: url('../../fonts/icon.eot');
    src: url('../../fonts/icon.eot#iefix') format('embedded-opentype'),
         url('../../fonts/icon.woff') format('woff'),
         url('../../fonts/icon.ttf') format('truetype'),
         url('../../fonts/icon.svg') format('svg#icon');
    font-weight: normal;
    font-style: normal;
}
