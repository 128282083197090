#block-sitewide-site-slogan-block {
  @extend .wrapper;

  & .sitewide-site-slogan {
    font-family: 'Nothing You Could Do';
    font-weight: bold;
    font-size: 2.4rem;

    @media (--screen-sm) {
      font-size: 3.1rem;
    }
  }
}
