.unpublished {
  #block-sitewide-page-title-block {
    position: relative;

    &::after {
      content: 'Unpublished';
      position: absolute;
      bottom: -16px;
      color: var(--warning);
      font-style: italic;
    }
  }
}
