#block-sitewide-social-block {
  line-height: 1;

  & .sitewide-social-link {
    display: inline-block;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin-right: 4px;
    margin-left: 4px;

    @media (--screen-sm) {
      margin-right: 16px;
      margin-left: 16px;
    }

    & a {
      display: block;
      width: 48px;
      height: 48px;
      font-size: 2.4rem;
      padding: 8px;

      @media (--screen-sm) {
        font-size: 3.2rem;
      }
    }

    & span {
      display: none;
    }
  }

  & .social-link-twitter {
    & a {
      @extend %icon-twitter;
    }
  }

  & .social-link-facebook {
    & a {
      @extend %icon-facebook;
    }
  }

  & .social-link-linkedin {
    a {
      @extend %icon-linkedin;
    }
  }

  & .social-link-constant_contact {
    a {
      @extend %icon-mail;
    }
  }

  & .social-link-rss {
    a {
      @extend %icon-rss;
    }
  }
}
