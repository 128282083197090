%icon {
  font-family: 'Icon';
  font-weight: normal;
}

%icon-permalink::after {
  @extend %icon;
  content: '\f101';
}

%icon-contrast::before {
  @extend %icon;
  content: '\f10a';
}

%icon-previous::before {
  @extend %icon;
  content: '\f104';
}

%icon-first::before {
  @extend %icon;
  content: '\f104\f104';
  letter-spacing: -0.5em;
}

%icon-external-link::after {
  @extend %icon;
  font-size: 50%;
  line-height: 1;
  margin-left: 12px;
  vertical-align: middle;
  opacity: 0.6;
  content: '\f102';
}

%icon-download::before {
  @extend %icon;
  content: '\f107';
  vertical-align: bottom;
}

%icon-facebook-boxed::before {
  @extend %icon;
  content: '\f10f';
}

%icon-facebook::before {
  @extend %icon;
  content: '\f10b';
}

%icon-next::before {
  @extend %icon;
  content: '\f103';
}

%icon-last::before {
  @extend %icon;
  content: '\f103\f103';
  letter-spacing: -0.5em;
}

%icon-google-plus::before {
  @extend %icon;
  content: '\f10c';
}

%icon-search::before {
  @extend %icon;
  content: '\f10d';
}

%icon-mail::before {
  @extend %icon;
  content: '\f108';
}

%icon-menu::before {
  @extend %icon;
  content: '\f106';
}

%icon-print::before {
  @extend %icon;
  content: '\f111';
}

%icon-info::before {
  @extend %icon;
  content: '\f105';
}

%icon-linkedin::before {
  @extend %icon;
  content: '\f10e';
}

%icon-scroll::before {
  @extend %icon;
  content: '\f109';
}

%icon-twitter::before {
  @extend %icon;
  content: '\f110';
}

%icon-rss::before {
  @extend %icon;
  content: '\f100';
}
