.views-slideshow-controls-bottom {
  @extend .wrapper;
  bottom: 40px;
  width: 100%;

  @media (--screen-sm) {
    bottom: 60px;
  }

  @media (--screen-md) {
    bottom: 80px;
  }
}

.views-slideshow-pager-fields {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  float: left;

  @media (--screen-sm) {
    left: 0;
    transform: none;
  }
}

.views-slideshow-pager-field-item {
  border: 3px solid var(--white);
  height: 22px;
  width: 22px;
  border-radius: 11px;
  transition: background-color 0.2s;
  cursor: pointer;
  float: left;
  margin-left: 3px;
  margin-right: 3px;
}

.views-slideshow-pager-field-item.active {
  background-color: var(--white);
}

.views-slideshow-pager-field-item * {
  display: none;
}
