.file {
  & .file-icon {
    display: none !important;
  }

  & a {
    @extend %icon-download;
    position: relative;
    display: block;
    padding-left: 1.5em;

    &::before {
      position: absolute;
      left: 0;
    }

    &:hover,
    &:focus {
      color: var(--primary);
    }
  }
}
